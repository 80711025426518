@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

body {
    font-size: 16px;
    color: #333;
    font-family: 'Lato', sans-serif;
}

p {
    font-size: 16px;
    color: #333;
}

.container {
    max-width: 1230px;
}

.header-wrap-home {
    background: transparent;
    padding: 20px 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 2;
}

.header-wrap {
    background: transparent;
    padding: 20px 0;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 2;
}

.header-wrap-resident {
    background: transparent;
    padding: 15px 0;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 2;
    background-color: rgba(9, 33, 98, .8);
    opacity: 0.9;
    position: absolute;
}

.header-wrap.fixed {
    background: #fff;
}

.main-logo img {
    max-width: 150px;
}

.main-menu ul {
    margin: 0;
    list-style: none;
}

.main-menu ul li a,
.main-menu ul li button {
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;
    color: #fff;
    padding: 0 30px;
    background: transparent;
    border: none;
}

.theme-btn {
    background: #09156b;
    border-radius: 30px;
    text-align: center;
    font-size: 17px;
    border: none;
    padding: 14px 40px;
    color: #fff;
    font-weight: 600;
}

.header-wrap .theme-btn {
    padding: 10px 30px;
    font-size: 15px;
}

.mb-overlay {
    background: linear-gradient(90deg, #100727 0%, rgba(16, 7, 39, .50) 100%);
    opacity: 1;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.mb-pos {
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;
}

.title-lg {
    font-size: 3em;
    color: #fff;
    line-height: 1.3em;
}

.title-heading {
    font-size: 2em;
    color: #fff;
    line-height: 1.3em;
}

.main-banner-wrap {
    height: 100vh;
    overflow: hidden;
}

.main-banner-wrap video {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}

.white-box {
    box-shadow: 0 0 30px #cccccc73;
    border: solid 1px #f3f3f3;
    position: relative;
    background: #fff;
}

.info-wrap .white-box:before {
    content: '';
    background: #09156b;
    height: 50%;
    width: 50%;
    position: absolute;
    left: -5px;
    top: -5px;
    z-index: -1;
    display: none;
}

.title-md {
    font-size: 20px;
    color: #333;
    text-align: center;
    font-weight: 600;
}

.info-wrap p {
    line-height: 22px;
    text-align: center;
}


.info-icon {
    min-height: 100px;
}

.info-icon img {
    max-height: 100px;
}

.services-wrap {
    background: #F7F9FD;
}

.py-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
}

.py-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
}

.text-black {
    color: #333 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.mxw-650 {
    max-width: 600px;
    margin: 0 auto;
}

.mxw-680 {
    max-width: 680px;
    margin: 0 auto;
}

.client-wrap {
    background: #09156B;
    background: radial-gradient(#36414E, #253241);
}

.client-flex .cf-col {
    text-align: center;
    padding: 0 2rem;
}

.footer-wrap {
    background-color: #21212b;
}

.ft-border-r {
    border-right: solid 1px #fff;
}

.city-img img {
    max-width: 90%;
}

.city-img-row-1 img {
    max-width: 70%;
}

.footer-top {
    overflow: hidden;
}

.footer-top p {
    margin-bottom: 0;
}

.footer-top p a {
    text-decoration: none;
    color: #d6d5d5;
}

.footer-logo img {
    max-width: 130px;
}

.footer-top .title-lg {
    font-size: 2.3em;
}

.sm-btn {
    font-size: 14px;
    border: none;
    padding: 13px 32px;
}

.footer-bottom {
    background-color: #1d1d25;
}

.fb-title {
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 18px;
    font-weight: 600;
}

.fb-menu {
    list-style: none;
    padding: 0;
    margin: 0;
}

.fb-menu li {
    color: #8c8c8c;
    font-size: 15px;
    padding: 3px 0;
}

.fb-menu li a {
    color: #8c8c8c;
    padding: 3px 0;
    text-decoration: none;
}

.copyright-block {
    background-color: #15151f;
    padding: 10px 0;
}

.social-links a {
    color: #fff;
    margin-left: 14px;
    text-decoration: none;
    font-size: 24px;
}

.copyright {
    font-size: 14px;
    color: #fff;
}

.overflow-hidden {
    overflow: hidden;
}

.scroll-down.arrow {
    position: absolute;
    left: 50%;
    margin-left: -25px;
    bottom: 55px;
    z-index: 1;
    opacity: 0.5;
    background: transparent;
    border: none;
}

.scroll-down.arrow span {
    position: absolute;
    display: inline-block;
    top: 20px;
    width: 50px;
    height: 50px;
    left: calc(50% - 1px);
    animation: animateArrow 1.5s infinite linear;
    color: #fff;
}

.scroll-down.arrow span::before {
    content: "";
    background: url(../img/down-arrow.png) no-repeat center center;
    background-size: 100%;
    width: 50%;
    height: 50%;
    display: inline-block;
}

body::-webkit-scrollbar {
    width: 6px;
}

body::-webkit-scrollbar-track {
    background: #ccc;
}

body::-webkit-scrollbar-thumb {
    background-color: #333;
}

.mobile-inner-header-icon {
    display: none;
}

.gradient-btn {
    text-shadow: rgba(0, 0, 0, 0.2) 0 -1px 0;
    border: 0px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 1px 2px, inset rgba(255, 255, 255, 0.88) 0px 1px 3px -1px;
    background-color: #4589E3;
    background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #5DA4FF), color-stop(100%, #417BFF));
    background: -webkit-linear-gradient(#5DA4FF, #417BFF);
    background: -moz-linear-gradient(#5DA4FF, #417BFF);
    background: -o-linear-gradient(#5DA4FF, #417BFF);
    background: linear-gradient(#5DA4FF, #417BFF);
    border-radius: 30px;
}


.sub-heading {
    font-weight: 400;
}

.main-banner-wrap .title-lg br {
    display: none;
}

.footer-address-img {
    opacity: 0.6;
}

.heading-typed-flip {
    color: #5DA4FF;
    font-size: 1em;
}

.hi-title {
    letter-spacing: -.03em;
    margin-top: 0;
    font-size: 2.8rem;
    font-weight: 600;
    line-height: 1.2;
    color: #000;
}

.hi-content p {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.45;
    text-align: left;
}

.info-wrap .white-box {
    box-shadow: none;
    border: none;
}

.info-wrap .white-box img {
    box-shadow: 0px 20px 50px rgb(0 0 0 / 40%);
    border-radius: 6px;
}

.pwu-icon {
    width: 10rem;
    height: 10rem;
    border-radius: 200px;
    margin: 0 auto;
    overflow: hidden;
    box-shadow: 0px 15px 30px rgb(0 0 0 / 25%);
}

.pwu-icon img {
    height: 100%;
    object-fit: cover;
}

.pwu-dark-blue {
    background-color: #041d34;
}

.pwu-purple {
    background-color: #8a78f0;
}

.pwu-green {
    background-color: #19c185;
}

.pwu-red {
    background-color: #fe565b;
}

.pwu-block .pwu-col {
    width: 25%;
}

.pwu-box .title-md {
    font-size: 1.5em;
    position: relative;
}

.testimonial-item {
    padding: 2rem 2rem;
}

.testimonial-box {
    box-shadow: 0 0 30px #cccccc;
    position: relative;
    background: #fff;
    border-radius: 24px;
    max-width: 430px;
}

.testimonial-name {
    font-size: 1.2em;
    font-weight: 600;
    line-height: 1.33;
    margin-bottom: 6px;
}

.testimonial-msg {
    font-size: 1em;
    line-height: 1.5;
}

.info-msg {
    position: absolute;
    bottom: 32px;
    max-width: 600px;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
    z-index: 1;
}

.info-msg p {
    color: rgb(255 255 255 / 50%);
    font-size: 14px;
}

.read-more-btn {
    background: rgb(211 211 211 / 80%);
    border-radius: 15px;
    border: none;
    font-size: 12px;
    padding: 2px 15px;
    color: #fff;
}

.read-more-modal {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    padding: 40px;
    background: #000;
    height: 70%;
    bottom: -100%;
    transition: all 0.5s ease-in-out 0s;
}

.read-more-modal.open {
    display: block;
    bottom: 0%;
}

.read-more-modal p {
    font-size: 17px;
    color: #fff;
}

.readmore-modal-body {
    max-width: 850px;
    margin: 0 auto;
}

.rm-close-icon {
    position: absolute;
    right: 25px;
    top: 25px;
    background: rgb(255 255 255 / 10%);
    border-radius: 5px;
    padding: 7px 11px 12px 11px;
    font-size: 21px;
    color: #fff;
    line-height: 14px;
    border: none;
    vertical-align: -7px;
}


.loading-wrap {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(90deg, #100727 0%, rgba(16, 7, 39, .50) 100%);
}

.loading-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.loading-icon img {
    max-width: 350px;
}

.tf-v1-popup .tf-v1-close {
    color: #fff !important;
    opacity: 1;
}

.dropdown-enterprise li a {
    color: #000 !important;
}

.logo-text {
    font-size: 12px;
}

.get-started {
    border: 3px solid #fff;
    padding: 3px 0px;
    border-radius: 10px;
}

.close-modal {
    background: transparent;
    border: none;
    font-size: 25px;
}

.modal-header {
    border-bottom: none !important;
    padding: 0 5px !important;
}

.dropdown-enterprise {
    background: transparent;
    border: none;
}

.modal-form {
    right: 0;
    padding: 16px;
    background-color: white;
    align-items: center;
    border-radius: 10px;
}

.fix-btn-position {
    position: relative;
}

.contact-btn {
    position: absolute;
    text-align: center;
}


/* contactus start */
.bg-dark-blue {
    background-color: #098FC2;
}

.bg-light-blue {
    background-color: #6CBCDA;
}

.header-bg {
    background-color: #3A4489;
}

.bg-image-tech {
    background-image: url(../img/bg/bg-tech/bg-tech-xl.jpg);
    height: 100vh;
    background-size: cover;
    background-position: center;
    position: relative;
    transition: all .2s ease-in-out .2s;
    opacity: 1;
}

.bg-image-ho {
    background-image: url(../img/bg/bg-ho/bg-ho-xl.jpg);
    height: 90vh;
    background-size: cover;
    background-position: center;
    position: relative;
    transition: all .2s ease-in-out .2s;
    opacity: 1;
    left: -200px;
}

.bg-image-hw {
    background-image: url(../img/bg/bg-hw/bg-hw-xl.png);
    height: 90vh;
    background-size: cover;
    background-position: center;
    position: relative;
    transition: all .2s ease-in-out .2s;
    opacity: 1;
    left: -200px;
}

.bg-image-pm {
    background-image: url(../img/bg/bg-pm/bg-pm-xl.png);
    height: 90vh;
    background-size: cover;
    background-position: right center;
    position: relative;
    transition: all .2s ease-in-out .2s;
    opacity: 1;
    left: -200px;
}

.form-position {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 70%;
    transform: translate(-50%, -50%);
    color: white;
}

.form-position-ho {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 90%;
    transform: translate(-50%, -50%);
    color: white;
}

.fixed-height-paragraph {
    height: 80px;
    overflow: hidden;
}

.fixed-height-heading {
    height: 50px;
    overflow: hidden;
}

.outer-form-container {
    background-color: #249AC7;
}

.inner-form-container1 {
    background-color: #123441;
    padding: inherit;
    border-radius: 20px;
}

.inner-form-container1 .thanks {
    color: #A8DD7E;
    font-size: 18px;
    font-weight: 600;
    margin-left: 5px;
}


/* .li {
    width: fit-content;
    margin-left: 2rem;
} */

/* contactus end */


/* Support page start utility css */
.section-heading {
    font-family: 'Myriad Pro', 'Lato', 'Helvetica Neue', 'Arial';
    color: #444;
    font-size: 26px;
    font-weight: 600;
}

.section-sub-heading {
    font-family: 'Myriad Pro', 'Lato', 'Helvetica Neue', 'Arial';
    color: #252525;
    font-size: 18px;
    font-weight: 500;
}

.section-content {
    font-family: 'Myriad Pro', 'Lato', 'Helvetica Neue', 'Arial';
    font-size: 15px;
    font-weight: 400;
    color: #666;
}

/* Support page end */


.error-message p {
    font-size: 28px;
    font-weight: 200;
    color: #4D728A;
    text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.75);
    margin-bottom: 0;
}

.error-message h1 {
    color: #fff;
    font-size: 11em;
    margin-bottom: 20px;
    font-weight: 200;
    letter-spacing: -3px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
}

canvas {
    background: linear-gradient(hsl(200, 50%, 80%) 0%,
            hsl(200, 30%, 95%) 75%);
    display: block;
}

header .btn.show {
    background-color: none !important;
    border-color: none !important;
}


/* About ( Our Story Section ) CSS start */
/* .about-section {
    margin-top: 15px;
} */

.about-section .team-message {
    background: radial-gradient(#36414E, #253241);
    padding: 65px 0 80px;
}

.about-section .team-message h4 {
    font-family: "Lato", "Helvetica Neue", Arial;
    text-align: center;
    font-size: 30px;
    color: #FFF;
    font-weight: 300;
    letter-spacing: .3px;
    margin-bottom: 40px;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.about-section .team-message button {
    padding: 10px 33px;
    border: 1px solid #68A2EE;
    background: linear-gradient(#5DA4FF, #417BFF);
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 1px 2px, inset rgba(255, 255, 255, 0.88) 0px 1px 3px -1px;
    transition: all 0.2s linear;
    color: #fff;
}

.about-section .founder-info img {
    margin: 0px 20px;
    max-width: 90px;
    border-radius: 10px;
    box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.4);
    transition: transform 0.5s ease, opacity 0.5s ease;
    /* Add transition effects */
    cursor: pointer;
}

.about-section .founder-info img:hover {
    transform: scale(1.2);
}

/* About ( Our Story Section ) CSS start */

/* Terms and Services Section CSS Start */

.terms-container {
    max-width: 750px;
    margin: auto;
    padding: 0 15px;
}

.termsofservice .termsofservice-heading {
    font-size: 30px;
    color: #071b50;
    letter-spacing: 1px;
    margin-bottom: 25px
}

.termsofservice .termsofservice-subheading {
    font-size: 14px;
    color: #071b50;
    letter-spacing: 1px;
    font-weight: 800;
}

.termsofservice .termsofservice-content {
    /* font-size: 15px; */
    color: #071b50;
    font-weight: 400;
    line-height: 1.45;
}

.termsofservice .termsofservice-content a {
    color: #00b9ff;
    text-decoration: none;
}

.termsofservice .termsofservice-content span {
    text-decoration: underline;
    font-size: 15px;
    color: #071b50;
    font-weight: 400;
    line-height: 1.45;
}

.termsofservice .list li {
    /* font-size: 13px; */
    line-height: 1.5;
}

.privacy-policy p {
    /* font-size: 15px; */
    color: #071b50;
    font-weight: 400;
    line-height: 1.45;
}

.privacy-policy h2 {
    font-size: 30px;
    color: #071b50;
    letter-spacing: 1px
}

.privacy-policy h2 a {
    font-size: 25px;
    color: #071b50;
    letter-spacing: 1px;
    text-decoration: none;
}

.privacy-policy h3 {
    font-size: 18px;
    color: #071b50;
    font-weight: 600;
}

.privacy-policy h3 a {
    font-size: 18px;
    color: #071b50;
    text-decoration: none;
    font-weight: 600;
}

.privacy-policy p a {
    color: #00b9ff;
    text-decoration: none;
}


.privacy-policy ol li {
    color: #00b9ff;
    font-size: 13px;
    margin-bottom: 10px;
    line-height: 1.5;
    text-decoration: none;
}

.privacy-policy ol li a {
    color: #00b9ff;
    ;
    text-decoration: none;
}

ol.lower-alpha-order {
    list-style-type: lower-alpha;
}

.privacy-policy .info-collected li {
    /* font-size: 13px; */
    margin-bottom: 10px;
    color: #071b50;
    line-height: 1.5;
}

.copyright h2 {
    font-size: 27px;
    color: #071b50;
    letter-spacing: 1px
}

.copyright h3 {
    font-size: 15px;
    color: #071b50;
    font-weight: 600;
}

.copyright p {
    /* font-size: 14px; */
    color: #071b50;
    line-height: 1.5;
    margin-bottom: 19px;
    font-weight: 400;
}

.copyright p a {
    color: #00b9ff;
}

.copyright .copyright-points li {
    /* font-size: 13px; */
    margin-bottom: 10px;
    color: #071b50;
    line-height: 1.5;
}

.copyright address>span {
    display: block;
    /* font-size: 13px; */
    line-height: 1.5;
    color: #071b50;
}

.copyright address>a {
    display: block;
    /* font-size: 13px; */
    line-height: 1.5;
    color: #00b9ff;
}

/* Terms and Services Section CSS End */

.wow-container {
    overflow: hidden;
}

/* Error page CSS start */
.error-section {
    overflow: hidden;
}

.error-content {
    text-align: center;
    margin-top: 45px;
    position: absolute;
    width: 100%;
}

.error-content h1 {
    color: #fff;
    font-size: 11em;
    margin-bottom: 20px;
    font-weight: 200;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
}

.error-content p {
    font-size: 28px;
    font-weight: 200;
    color: #4D728A;
    text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.75);
    margin-bottom: 0;
    font-weight: 500;

}

.error-content .error-back {
    font-size: 17px;
    font-weight: 200;
    color: #4D728A;
    text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.75);
    margin-bottom: 0;
    font-weight: 500;

}

.error-content a {
    color: #429AD5;
    text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.4);
    font-size: 18px;
    text-decoration: none;
    font-size: 17px;
}

.error-animation {
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    background: url(http://jackrugile.com/images/misc/skyline-texture.png)
}

/* Error page CSS end */


/* Resident section CSS start */
.resident-bg-dark-blue {
    background-color: #065674;
}

.resident-bg-light-blue {
    background-color: #098FC2;
}

.resident-bg-fade-blue {
    background-color: #0A8FC1;
}

.resident-how-it-work-bg {
    background-color: #9ae0fb;
}

.bg-image-resident {
    background-image: url(../img/bg/bg-resident/resident-bg-desktop-view.jpg);
    height: 100vh;
    background-size: cover;
    background-position: center;
    position: relative;
    transition: all .2s ease-in-out .2s;
    opacity: 1;
}

.resident-how-it-work-section img {
    width: 200px;
}

.resident-how-it-work-section h2 {
    color: #065674 !important;
    text-align: center;
    font-size: 30px;
    line-height: 1;
    margin: 0 0 35px;
}

.resident-how-it-work-section p {
    color: #065674 !important;
    font-size: 14px;
}

.form-position-resident {
    text-align: center;
    position: absolute;
    top: 60%;
    left: 80%;
    transform: translate(-50%, -50%);
    color: white;
}

.text-position-resident {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 2em;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    text-align: center;
}

.resident-banner h2 {
    font-size: 50px;
    margin-bottom: 9px;
    text-shadow: 1px 0 1px #ccc, 0 1px 1px #4b54dd, 1px 1px 1px #ccc, 1px 1px 1px #4b54dd, 1px 1px 1px #ccc, 1px 1px 1px #4b54dd, 1px 1px 1px #ccc, 1px 1px 1px #4b54dd, 1px 1px 1px #ccc, 1px 1px 1px #4b54dd, 1px 1px 1px #ccc, 1px 1px 1px #4b54dd, 1px 1px 1px #ccc;
    transition: opacity .4s;
    opacity: 1;
    min-width: 70%;
    /* Set a width for the text overlay */
    padding: 0 25px;
}

.resident-banner h3 {
    font-size: 20px;
    background: rgba(9, 33, 98, .6);
    line-height: 1.7;
    min-height: 37px;
    margin: 0 auto;
    padding: 0 20px;
    transition: opacity 2s .5s;
    opacity: 1;
    border-radius: 10px;
}

/* Resident section CSS end */

.PhoneInputInput {
    border: none;
}

.PhoneInputInput:focus {
    outline: none;
    box-shadow: none;
}

.learn-more {
    background: #5294FF;
    border: none;
    border-radius: 7px;
    color: #fff;
    font-size: 11px;
    padding: 2px 10px 5px 10px;
    cursor: pointer;
}

.modal-header .btn-close {
    padding: 28px !important;
}

.contact-modal-header {
    background-color: #249AC7;
    color: #fff;
}

/*  */
.kh-white-box {
    padding: 30px;
    border-radius: 20px;
    position: absolute;
    z-index: 9;
    background-color: #fff;
    color: #000;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 700px;
    max-height: 95vh;
    /* Adjust the height as needed */
}

.lg-title {
    color: #595eda;
    font-size: 1.8em;
    font-weight: 600;
}

.kh-white-box .theme-btn {
    padding: 6px 25px;
    background: #595eda;
    border-radius: 10px;
    text-align: center;
    font-size: 17px;
    border: none;
    color: #fff;
    font-weight: 600;
}


.custom-modal .modal-dialog {
    max-width: 700px;
    margin: 0 auto;
}

.custom-modal .modal-content {
    border: 0px;
    border-radius: 15px;
    padding: 0;
    margin-bottom: 15px;
}

.custom-modal .modal-body {
    overflow: hidden;
    border-radius: 15px;
}

.custom-modal .close {
    background: #0000;
    border: none;
    color: #ff203e;
    font-size: 28px;
    font-weight: 700;
    line-height: 20px;
    opacity: 1;
    position: absolute;
    right: 25px;
    top: 15px;
    z-index: 1;
}

.custom-modal .modal-header {
    padding: 5px 0 15px;
    border-bottom: none;
}

.custom-modal .modal-body {
    padding: 0;
}

.custom-modal .modal-body iframe {
    display: block;
}

.contact-keepe-modal .modal-content {
    margin-bottom: 0;
}

.contact-keepe-modal .modal-dialog {
    max-width: 50%;
    min-height: 100%;
}

.connect-keepe-bg {
    background-color: #fff;
    width: 100%;
    height: 300px;
    position: relative;
}

.kh-white-box::-webkit-scrollbar {
    width: 6px;
}

.kh-white-box::-webkit-scrollbar-track {
    background: #ccc;
}

.kh-white-box::-webkit-scrollbar-thumb {
    background-color: #333;
}

.kh-white-box .thanks-close-btn {
    color: #fff;
    background-color: #4589E3;
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 15px;
}

.thanks-block {
    text-align: center;
}

.city-heading {
    font-size: 15px !important;
}

.thanks-heading {
    font-weight: bold;
    font-size: 16px;
    margin-left: 8px;
}

.form-heightlight li {
    text-align: left;
}

.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    color: #00b9ff;
    text-decoration: none;
}

/* terms-of-use  */

.hdng-txt {
    font-size: 14pt;
    color: rgba(34, 110, 147, 1);
    font-weight: 500;
    font-family: Lato, sans-serif;
}

.ul-dot {
    list-style-type: disc;
}

.ul-num {
    list-style-type: decimal;
}


.terms-of-use .heading {
    color: #000000;
    font-size: 12.0pt;
    font-variant: normal;
    font-weight: 700;
    vertical-align: baseline;
}

.terms-of-use .content {
    color: #000000;
    font-family: Arial;
    font-size: 12.0pt;
    font-weight: 400;
    vertical-align: baseline;
}

.terms-of-use .table-heading {
    color: #00b9ff;
    font-size: 15pt;
    font-weight: 400;
    margin-top: 20px;
}

.terms-of-use .content span {
    font-weight: 600;
}

.terms-of-use .content a {
    color: #00b9ff;
    font-weight: 500;
    font-size: 12.0pt;
}

.terms-of-use .link-button-terms {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    color: #00b9ff;
    list-style: none;
    list-style-type: none;
    text-decoration: underline;
}

.subbtn {
    padding-left: 10px;

}

.list-square li a {
    color: #00b9ff;
    font-weight: 500;
    font-size: 12.0pt;

}

.table-content-terms li {
    margin-top: 8px;
    list-style: none;
}

.link-span {
    color: #00b9ff;
    font-family: Arial;
    font-weight: 500 !important;
}

.list-square {
    list-style-type: square;
}

.terms-of-use h2 {
    font-size: 30px;
    color: #071b50;
    letter-spacing: 1px;
}


.terms-of-use ul li {
    color: #000000;
    font-family: Arial;
    font-size: 12.0pt;
    font-weight: 400;
    vertical-align: baseline;
}

.terms-heading {
    color: #00b9ff;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 0.5px;
    line-height: 1.2;
}

.address-row span,
.address-row a {
    display: block;
}

.address-row a {
    color: #00b9ff;
}