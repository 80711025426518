@media(max-width: 1366px) {
    .container {
        max-width: 1140px;
    }
}

@media(max-width: 1199px) {
    .container {
        max-width: 960px;
    }

    .title-lg {
        font-size: 2em;
    }

    .title-md {
        font-size: 17px;
    }

    .info-wrap .title-md {
        font-size: 19px;
    }

    .testimonial-text p {
        line-height: 27px;
        font-size: 15px;
    }

    .feat-img .th-name h5 {
        font-size: 1em;
    }

    .feat-img .th-name h6 {
        font-size: 12px;
    }

    .feat-img .th-name img {
        max-width: 70px;
    }

    .client-flex .cf-1 img,
    .client-flex .cf-2 img {
        max-height: 42px;
    }

    .hi-title {
        font-size: 2.2rem;
    }

    .hi-content p {
        font-size: 1.1rem;
    }

    .info-wrap.py-10 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }

    .pwu-icon {
        width: 14rem;
        height: 14rem;
    }

    .bg-image-tech {
        background-image: url(../img/bg/bg-tech/bg-tech-lg.jpg);
        transition: all .2s ease-in-out .2s;
        opacity: 1;
    }

    .form-position {
        left: 40%;
        top: 50%;
    }
}

@media(max-width: 992px) {

    .gx-5 {
        --bs-gutter-x: 1.5rem;
    }

    .get-started {
        border: none;
        padding: 0;
        border-radius: 0;
    }

    .footer-logo img {
        max-width: 100px;
    }

    .footer-top .title-lg {
        font-size: 1.9em;
    }

    .footer-top h4 {
        font-size: 1.1em;
    }

    .footer-top p {
        font-size: 15px;
    }

    .py-10 {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important;
    }

    .px-4 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }

    .scroll-down.arrow span {
        width: 40px;
        height: 40px;
    }

    .mobile-inner-header-icon {
        color: #fff;
        height: 20px;
        font-size: 25px;
        text-align: center;
        width: 40px;
        position: relative;
        -webkit-transition: background .5s;
        -moz-transition: background .5s;
        -o-transition: background .5s;
        transition: background .5s;
        margin-top: 16px;
        background: transparent;
        border: none;
        display: block;
    }

    .mobile-inner-header-icon:hover {
        cursor: pointer
    }

    .mobile-inner-header-icon span {
        position: absolute;
        left: calc((100% - 20px)/ 2);
        top: calc((100% - 21px)/ 2);
        width: 25px;
        height: 2px;
        background-color: rgb(255, 255, 255)
    }

    .mobile-inner-header-icon span:nth-child(1) {
        transform: translateY(4px) rotate(0)
    }

    .mobile-inner-header-icon span:nth-child(2) {
        transform: translateY(-4px) rotate(0)
    }

    .mobile-inner-header-icon span:nth-child(3) {
        display: none
    }

    .mobile-inner-header-icon-click {
        position: fixed;
        right: 30px;
        top: 30px;
        z-index: 9;
    }

    .mobile-inner-header-icon-click span:nth-child(1) {
        animation-duration: .5s;
        animation-fill-mode: both;
        animation-name: clickfirst
    }

    .mobile-inner-header-icon-click span:nth-child(2) {
        animation-duration: .5s;
        animation-fill-mode: both;
        animation-name: clicksecond
    }

    @keyframes clickfirst {
        0% {
            transform: translateY(4px) rotate(0)
        }

        100% {
            transform: translateY(0) rotate(45deg)
        }
    }

    @keyframes clicksecond {
        0% {
            transform: translateY(-4px) rotate(0)
        }

        100% {
            transform: translateY(0) rotate(-45deg)
        }
    }

    .mobile-inner-header-icon-out span:nth-child(1) {
        animation-duration: .5s;
        animation-fill-mode: both;
        animation-name: outfirst
    }

    .mobile-inner-header-icon-out span:nth-child(2) {
        animation-duration: .5s;
        animation-fill-mode: both;
        animation-name: outsecond;
        margin-top: 3px;
        top: calc((100% - 19px)/ 2)
    }

    .mobile-inner-header-icon-out span:nth-child(3) {
        display: block;
        top: calc((100% - 17px)/ 2)
    }

    @keyframes outfirst {
        0% {
            transform: translateY(0) rotate(-45deg)
        }

        100% {
            transform: translateY(-4px) rotate(0)
        }
    }

    @keyframes outsecond {
        0% {
            transform: translateY(0) rotate(45deg)
        }

        100% {
            transform: translateY(4px) rotate(0)
        }
    }

    .mobile-inner-header-icon.mobile-inner-header-icon-click span {
        background-color: #fff
    }

    .main-menu {
        position: fixed;
        left: 0;
        height: 100%;
        top: -100%;
    }

    .main-menu.active {
        transition: all 0.5s ease-in-out 0s;
        background: rgb(9, 21, 107);
        width: 100%;
        margin: 0;
        transition: all .3s;
        padding: 70px 40px;
        overflow: auto;
        z-index: 3;
        top: 0;
    }

    .header-wrap {
        z-index: 9;
    }

    .main-menu ul {
        display: block !important;
        padding: 20px 0 0;
    }

    .main-menu ul li a,
    .main-menu ul li button {
        padding: 13px 10px;
        color: #fff;
        font-size: 18px;
        display: block;
    }

    .info-msg {
        bottom: 32px;
        left: inherit;
        transform: inherit;
        max-width: 100%;
        padding: 0 20px;
    }

    .hi-title {
        font-size: 2rem;
        line-height: 1.1;
    }

    .hi-content p {
        font-size: 1rem;
    }

    .pwu-icon {
        width: 11rem;
        height: 11rem;
        margin-bottom: 0.7rem !important;
    }

    .pwu-box .title-md {
        font-size: 1.3em;
    }

    .read-more-modal p {
        font-size: 14px;
    }

    .mnw-flex {
        min-width: 70px;
    }

    .text-position-resident {
        width: 80%;
    }

    .resident-banner h3 {
        font-size: 15px;
    }

    .resident-banner h2 {
        font-size: 20px;
    }

    .bg-image-tech {
        background-image: url(../img/bg/bg-tech/bg-tech-md.jpg);
        transition: all .2s ease-in-out .2s;
        opacity: 1;

    }

    .form-position {
        left: 50%;
        top: 50%;
    }

    .bg-image-pm {
        background-image: url(../img/bg/bg-pm/bg-pm-lg.png);
        height: 90vh;
        background-size: cover;
        background-position: center;
        position: relative;
        transition: all .2s ease-in-out .2s;
        opacity: 1;
        left: -200px;
        background-repeat: no-repeat;
    }
    .bg-image-ho {
        background-image: url(../img/bg/bg-ho/bg-ho-md.jpg);
        height: 90vh;
        background-size: cover;
        background-position: center;
        position: relative;
        transition: all .2s ease-in-out .2s;
        opacity: 1;
        left: -200px;
    }
}

@media(max-width: 768px) {
    .bg-image {
        background-image: url("https://cdn.keepe.com/assets/images/keeper_campaign/1024-bg.jpg");
    }

    .fixed-height-paragraph,
    .fixed-height-heading {
        height: auto;
        overflow: visible;
    }

    .main-logo img {
        max-width: 140px;
    }

    .container {
        padding: 0 25px
    }

    .info-wrap .col-md-4+.col-md-4 {
        margin-top: 30px;
    }

    .info-wrap .white-box {
        padding-bottom: 30px !important;
        max-width: 400px;
        margin: 0 auto;
    }

    .testimonials-wrap .col-md-4+.col-md-4 {
        margin-top: 30px;
    }

    .testimonial-item {
        max-width: 400px;
        margin: 0 auto;
        display: block;
    }

    .footer-top .col-xl-4 {
        margin-bottom: 35px;
        text-align: center !important;
    }
    .footer-top .col-xl-8 {
        margin-bottom: 35px;
        text-align: center !important;
    }

    .footer-top .title-lg {
        margin-bottom: 1.2rem !important;
        text-align: center !important;
    }

    .footer-top .title-lg br {
        display: none;
    }

    .footer-bottom .col-md-8 {
        margin-top: 20px;
    }

    .fb-title {
        margin-bottom: 10px;
    }

    .services-wrap .info-icon {
        min-height: 70px;
    }

    .services-wrap .info-icon img {
        max-height: 70px;
    }

    .mb-pos {
        top: 45%;
    }

    .hi-title {
        font-size: 1.7rem;
        line-height: 1.1;
    }

    .hi-title.mb-3 {
        margin-bottom: 0.5rem !important;
    }

    .info-wrap.py-10+.info-wrap.py-10 {
        padding-top: 0 !important;
    }

    .info-wrap.py-10 .order-2 {
        order: inherit !important;
    }

    .hi-content,
    .hi-content p {
        text-align: center;
    }

    .rm-close-icon {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .read-more-modal {
        padding: 40px 20px 20px;
    }

    .readmore-modal-body {
        overflow-y: auto;
        height: 100%;
    }

    .pwu-block .pwu-col {
        width: 50%;
    }

    .services-wrap .title-md {
        font-size: 15px;
    }

    .form-position {
        left: 50%;
    }

    .bg-image-resident {
        background-image: url(../img/bg/bg-resident/resident-bg-mid-view.jpg);
        transition: all .2s ease-in-out .2s;
        opacity: 1;
    }

    .text-position-resident {
        width: 80%;
    }

    .resident-banner h3 {
        font-size: 12px;
    }

    .resident-banner h2 {
        font-size: 17px;
    }
    .connect-keepe-bg{
        height: 570px;
    }
    .custom-modal .close{
        right: 10px;
        top: 14px;
    }
    .kh-white-box .mt-2{
        margin-top: 0 !important;
    }
    .kh-white-box .lg-title{
        font-size: 1em;
    }
    .kh-white-box {
       width: 500px;
    }
    .bg-image-hw {
        background-image: url(../img/bg/bg-hw/bg-hw-lg.png);
        height: 90vh;
        background-size: cover;
        background-position: center;
        position: relative;
        transition: all .2s ease-in-out .2s;
        opacity: 1;
        left: -200px;
        background-repeat: no-repeat;
    }


}

@media(max-width: 575px) {
    /* #background-video {
        height: 65%; 
      }
      .mb-pos {
        top: 60%;
    } */
    .container {
        padding: 0 15px;
    }


    .ft-border-r {
        border-right: none;
    }


    .copyright-block .d-flex {
        display: block !important;
        text-align: center;
    }

    .social-links {
        margin-bottom: 7px;
    }

    .client-flex .cf-col {
        padding: 0 1rem;
    }

    .client-flex .cf-col img {
        max-width: 170px;
    }

    .theme-btn {
        font-size: 15px;
        padding: 10px 34px;
    }

    .bg-image-tech {
        background-image: url(../img/bg/bg-tech/bg-tech-sm.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        padding-top: 200px;
        height: 100% !important;
        background-position: top;
        transition: all .2s ease-in-out .2s;
        opacity: 1;
    }

    .bg-image-ho {
        background-image: url(../img/bg/bg-ho/bg-ho-sm.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        padding-top: 250px;
        height: 100% !important;
        background-position: top;
        transition: all .2s ease-in-out .2s;
        opacity: 1;
        left: 0;
    }

    .bg-image-hw {
        background-image: url(../img/bg/bg-hw/bg-hw-sm.png);
        background-size: cover;
        background-repeat: no-repeat;
        padding-top: 250px;
        height: 100% !important;
        background-position: center;
        transition: all .2s ease-in-out .2s;
        opacity: 1;
        left: 0;
    }

    .bg-image-pm {
        background-image: url(../img/bg/bg-pm/bg-pm-sm.png);
        background-size: cover;
        background-repeat: no-repeat;
        padding-top: 250px;
        height: 100% !important;
        background-position: top;
        transition: all .2s ease-in-out .2s;
        opacity: 1;
        left: 0;
    }

    .form-position {
        left: 50%;
        bottom: 0;
        top: 100%;
    }

    .form-position-ho {
        left: 50%;
        bottom: 0;
        top: 100%;
    }
    .custom-modal .close {
        right: 6px;
        top: 15px;
    }
    .city-img img{
        max-width: 70%;
    }
    .kh-white-box {
        width: 100%;
    }
    .footer-top .col-sm-4{
        margin-top: 20px;
    }
    .footer-position{
        margin-top: 595px;
    }
    .kh-white-box {
        width: 300px;
     }
}

@media(max-width: 480px) {
    .pwu-icon {
        width: 8rem;
        height: 8rem;
        margin-bottom: 0.7rem !important;
        box-shadow: 0px 10px 24px rgb(0 0 0 / 25%);
    }

    .pwu-block .mt-5 {
        margin-top: 2rem !important;
    }

    .client-wrap .title-lg.mb-5 {
        margin-bottom: 1rem !important;
    }

    .resident-banner h2 {
        font-size: 23px;
    }

    .resident-banner h3 {
        font-size: 15px;
    }

    .bg-image-resident {
        background-image: url(../img/bg/bg-resident/resident-bg-mob-view.jpg);
        transition: all .2s ease-in-out .2s;
        opacity: 1;
    }

    .text-position-resident {
        width: 70%;
    }

    .text-position-resident {
        top: 70%;
    }
    .footer-position{
        margin-top: 590px;
    }
}